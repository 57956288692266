<template>
    <li>
        <a class="transition-colors duration-300 text-cMidGrey hover:text-cWhite" 
        :href="url" target="_blank" rel="noopener noreferrer">
            <v-icon :name="icon" scale="1.6" />
        </a>
    </li>
</template>

<script>
export default {
    name: "SocialMediaItem",
    props: {
        icon: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
};
</script>
