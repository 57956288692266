<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-[1fr_3fr] gap-x-4">
            <div class="order-last md:order-first ">
                <img :src="thumbnail" :alt="alt" 
                class="md:w-full h-[120px] md:h-auto object-contain border-3 rounded border-cBurgundy">
            </div>
            <div>
                <h5 class="text-cWhite text-lg font-semibold tracking-tighter">{{ title }}</h5>
                <p class="text-cLightGray tracking-wider leading-6 text-justify font-nokora text-sm font-light">
                    {{ description }}
                </p>
                <div class="my-4 flex flex-wrap">
                    <tech-mark v-for="(technology, index) in tech" :key="index" :text="technology" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TechMark from './TechMark.vue';

export default {
    name: 'ProjectCard',
    components: {
        TechMark
    },
    props: {
        title: {
            type: String,
            required: true
        },
        thumbnail: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: false
        },
        description: {
            type: String,
            required: true
        },
        tech: {
            type: Array,
            required: false
        }
    }
}
</script>