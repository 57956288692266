<template>
    <div class="space-y-8">
        <div class="space-y-0.5">
            <a href="/" class="text-cMidGrey flex gap-2 items-center transition duration-300 w-fit hover:text-cBurgundy ">
                <v-icon name="fa-arrow-left" scale="0.9" class="h-fit pb-0.5" />
                <span class="font-nokora font-bold">Strona główna</span>
            </a>
            <h3 class="text-cWhite font-bold font-nokora text-2xl ps-2">Lista projektów</h3>
        </div>

        <div>
            <table class="projects-table min-w-full table-auto text-cLightGray">
                <thead class="tracking-tighter font-bold text-sm text-left border-b border-cDarkGrey">
                    <tr>
                        <th>Rok</th>
                        <th>Projekt</th>
                        <th class="hidden md:table-cell">Dla</th>
                        <th class="hidden sm:table-cell">Technologia</th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-cDarkGrey text-sm tracking-tight">
                    <tr v-for="(p, index) in sortProjects" :key="index">
                        <td>{{ p.date }}</td>
                        <td>
                            <div class="flex gap-1 items-center break-words">
                                <span class="font-bold">{{ p.name }}</span>
                                <a :href="p.url" v-if="p.url" class="pb-1" target="_blank" rel="noopener noreferrer">
                                    <v-icon name="bi-box-arrow-up-right" class="text-cBurgundy goto-mark" />
                                </a>
                            </div>
                        </td>
                        <td class="hidden md:table-cell">{{ p.for }}</td>
                        <td class="hidden sm:table-cell">
                            <div class="flex flex-wrap w-full h-full">
                                <tech-mark v-for="(t, index) in p.tech" :key="index" :text="t" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style>
.projects-table th,
.projects-table td {
    @apply px-4 py-3 align-middle h-full;
}

.goto-mark {
    filter: drop-shadow(0 0 5px #800020) drop-shadow(0 0 10px #800020) drop-shadow(0 0 15px #800020);
}
</style>

<script>
import TechMark from '../common/components/TechMark.vue';

export default {
    name: 'ArchivePage',
    components: {
        TechMark
    },
    data() {
        return {
            projects: [
                {
                    date: 2024,
                    name: '[W TRAKCIE] Integracja z Baselinker',
                    for: 'Konfodata',
                    tech: ['C#', 'REST API', 'Microsoft SQL Server' ]
                },
                {
                    date: 2024,
                    name: 'Projekt i implementacja - weclawiak.pl',
                    url: 'http://www.weclawiak.pl',
                    for: '',
                    tech: [ 'Vue.js', 'TailwindCSS', 'Ubuntu', 'nginx', 'Figma']
                },
                {
                    date: 2023,
                    name: 'Automatyzacja ustalania cen produktów',
                    for: 'Mastersport',
                    tech: [ 'Python', 'Microsoft SQL Server']
                },
                {
                    date: 2023,
                    name: 'Projekt i implementacja - slomapiotr.pl',
                    url: 'https://slomapiotr.pl',
                    for: 'Piotr Słoma',
                    tech: ['PHP', 'SCSS', 'JavaScript', 'Figma']
                },
                {
                    date: 2023,
                    name: 'Platforma do tworzenia specyfikacji produktów',
                    for: 'Mastersport',
                    tech: [ 'PHP', 'Laravel', 'Alpine.js', 'JavaScript', 'Livewire', 'PostgreSQL' ]
                },
                {
                    date: 2023,
                    name: 'Integracja z Baselinker',
                    for: 'Monotox',
                    tech: ['C#', 'REST API', 'Microsoft SQL Server' ]
                },
                {
                    date: 2023,
                    name: 'Implementacja chatbota z nadzorowanym uczeniem maszynowym',
                    for: 'Praca dyplomowa',
                    tech: [ 'Python', 'CSS', 'MariaDB', 'REST API', 'nginx' ]
                },
                {
                    date: 2022,
                    name: 'Integracja z Baselinker',
                    for: 'The Brands Partners',
                    tech: ['C#', 'REST API', 'Microsoft SQL Server' ]
                },
                {
                    date: 2022,
                    name: 'Automatyzacja śledzenia sezonowości produktu',
                    for: 'Mastersport',
                    tech: [ 'C#', 'Microsoft SQL Server' ]
                },
                {
                    date: 2021,
                    name: 'Integracja z mastersport.pl',
                    for: 'Mastersport',
                    tech: ['C#', 'WCF', 'SOAP API', 'Microsoft SQL Server']
                },
                {
                    date: 2021,
                    name: 'Integracja z Baselinker',
                    for: 'Mastersport',
                    tech: ['C#', 'REST API', 'Microsoft SQL Server', 'PostgreSQL']
                },
                {
                    date: 2021,
                    name: 'Automatyzacja importu zamówień internetowych i dokumentów sprzedaży',
                    for: 'Mastersport',
                    tech: ['C#', 'WinForms', 'Microsoft SQL Server']
                },
                {
                    date: 2021,
                    name: 'Integracja z 1BUT',
                    for: 'Mastersport',
                    tech: [ 'C#', 'Microsoft SQL Server' ]
                }, 


            ]
        }
    },
    computed: {
        sortProjects() {
            return [...this.projects].sort((a, b) => b.date - a.date);
        }
    }
}
</script>