import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../components/views/HomePage.vue';
import ArchivePage from '../components/views/ArchivePage.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '/archive',
            name: 'archive',
            component: ArchivePage
        }
    ]
});

export default router;