<template>
    <div class="tech-icon flex flex-col items-center space-y-4">
        <div class="h-[60px] w-[60px] md:h-[80px] md:w-[80px]">
            <img :src="src" :alt="alt" class="h-[80px] object-contain">
        </div>
        <span class="font-niramit font-bold text-sm md:text-base w-fit h-fit">{{ text }}</span>
    </div>
</template>

<style scoped>
.tech-icon img {
    filter: grayscale(1) brightness(90%) contrast(90%);
    transition: filter .3s ease;
}

@media (pointer: coarse) and (max-width: 767px) {
    .tech-icon img {
        filter: grayscale(0) brightness(100%) contrast(100%);
    }
}

.tech-icon:hover img {
    filter: grayscale(0) brightness(100%) contrast(100%)     
            drop-shadow(0 0 20px #800020) 
            drop-shadow(0 0 30px #800020) 
            drop-shadow(0 0 40px #800020);
}
</style>

<script>
export default {
    name: 'TechIcon',
    props: {
        text: {
            type: String,
            required: true
        },
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: false
        }
    }
}
</script>