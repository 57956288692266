<template>
    <span class="text-cWhite bg-cBurgundy text-sm font-semibold font-niramit px-4 rounded-xl mb-2 py-0.5 me-2">
        {{ text }}
    </span>
</template>

<script>
export default {
    name: 'TechMark',
    props:
    {
        text: {
            type: String, 
            required: true
        }
    }
}
</script>