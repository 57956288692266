<template>
    <div class="grid grid-cols-1 sm:grid-cols-[90px_auto_4fr] gap-x-3 py-4 px-1 lg:px-10">
        <div class="text-cMidGrey font-bold font-inter text-sm tracking-wider flex space-x-2 justify-end">
            <span>{{ period }}</span>
        </div>

        <div class="bg-cDarkGrey w-px hidden sm:block"></div>

        <div class="space-y-1">
            <div class="flex justify-between items-center font-inter">
                <h5 class="text-cWhite text-lg font-bold tracking-tighter">{{ role }}</h5>
                <span class="text-cLightGray text-sm font-semibold">{{ company }}</span>
            </div>

            <p class="text-cLightGray tracking-wider leading-6 text-justify font-nokora text-sm font-light">
                {{ description }}
            </p>

            <div class="pt-2 flex flex-wrap">
                <tech-mark v-for="(technology, index) in tech" :key="index" :text="technology" />
            </div>
        </div>
    </div>
</template>

<script>
import TechMark from './TechMark.vue';

export default {
    name: 'CarrierCard',
    components: {
        TechMark
    },
    props: {
        period: {
            type: String,
            required: true
        },
        role: {
            type: String,
            required: true
        },
        company: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        tech: {
            type: Array,
            required: false
        }
    }
}
</script>