<template>
    <a :href="url" class="redirect-link text-cWhite tracking-widest font-bold hover:text-cBurgundy underline underline-offset-8 flex items-center w-fit">
        <v-icon name="bi-arrow-left-short" scale="2.3" class="font-light"/>
        <span>{{ text }}</span>
    </a>
</template>

<style>
.redirect-link {
    transition: all .3s ease;
    cursor: pointer;
}

.redirect-link:hover {
    @apply text-cBurgundy;
    filter: drop-shadow(0 0 20px #800020) 
            drop-shadow(0 0 30px #800020) 
            drop-shadow(0 0 40px #800020);
}
</style>

<script>
export default {
    name: 'RedirectLink',
    props: {
        text: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    }
}
</script>