<template>
	<div class="min-h-screen w-full max-w-[1536px] flex lg:justify-between gap-8 mx-auto relative
				flex-col font-nokora px-6 py-12 md:px-12 md:pt-20 lg:px-12 lg:flex-row lg:gap-14">

		<!-- <div class="highlight-point top-0 right-0"></div> -->

		<!-- Sidebar -->
		<sidebar v-if="sidebarAllow" />
		<!-- Main content -->
		<main class="w-full">
			<router-view />
			<footer
				class="text-cLightGray text-xs tracking-widest w-full flex flex-col text-center items-center justify-center pt-20 pb-0 font-inter">
				<span class="lg:w-10/12">
					Strona zaprojektowana w <b>Figma</b> i kodowana w <b>Visual Studio Code</b>, zbudowana z <b>Vue.js</b> i
					<b>TailwindCSS</b>. Kodowana z kubkiem ulubionej kawy - bo każde działanie wymaga kofeiny.
				</span>
				<span class="mt-3">© Created by <b>Eryk Węcławiak</b> 2024</span>
			</footer>
		</main>
	</div>
</template>

<style>
::-webkit-scrollbar {
	width: 12px
}
::-webkit-scrollbar-track {
    background: #0B0202;
}
::-webkit-scrollbar-thumb {
    background-color: #800020;
    border-radius: 6px; 
    border: 3px solid #0B0202; 
}
::-webkit-scrollbar-thumb:hover {
    background: #606060; 
}

.highlight-point {
	@apply absolute;
	width: 600px;
	height: 600px;
	background-image: radial-gradient(circle, rgba(128, 0, 32, 0.5), transparent 70%);
	pointer-events: none;
}
</style>

<script>
import { useRoute } from 'vue-router';
import Sidebar from './components/layout/SidebarLayout.vue'
import { ref, watch } from 'vue';

export default {
	name: 'App',
	components: {
		Sidebar
	},
	setup() {
		const route = useRoute();
		const sidebarAllow = ref(route.name === 'home');

		watch(() => route.name, (newName) => {
			sidebarAllow.value = newName === 'home';
		});

		return { sidebarAllow };
	}
}
</script>
