<template>
    <header class="lg:sticky lg:max-h-screen lg:w-2/5 xl:w-1/4 lg:top-0 lg:py-24
                    flex flex-col justify-between   text-cMidGrey">
        <!-- Content container -->
        <div class="space-y-36">

            <!-- Bio container -->
            <div class="space-y-4 lg:space-y-7">
                <!-- Person container -->
                <div>
                    <h1 class="text-4xl tracking-tighter font-black text-cWhite">Eryk Węcławiak</h1>
                    <h2 class="text-3xl tracking-tighter font-bold ">Programista</h2>
                </div>
                <!-- Bio info container -->
                <div class="space-y-4">
                    <hr class="h-px bg-cMidGrey border-0 mx-2 w-2/5">
                    <p class="tracking-widest w-full md:w-1/2 lg:w-full">
                        "Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way." – 
                        <b>Michael Scott</b>
                    </p>
                </div>
            </div>

            <!-- Navigation container -->
            <nav class="hidden lg:block">
                <ul class="list-none space-y-3 font-bold uppercase font-inter text-sm">
                    <nav-item v-for="(link, index) in navLinks" :key="link.id" :url="link.url" :text="link.text"
                        :isActive="activeIndex === index" />
                </ul>
            </nav>
        </div>

        <!-- Social media container -->
        <div class="mt-5 lg:mt-0">
            <ul class="list-none flex gap-6">
                <social-media-item v-for="link in socialLinks" :key="link.id" :icon="link.icon" :url="link.url" />
            </ul>
        </div>
    </header>
</template>

<script>
import SocialMediaItem from '../common/components/SocialMediaItem.vue';
import NavItem from '../common/components/NavItem.vue';
import { inject, computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    name: 'SidebarLayout',
    components: {
        SocialMediaItem,
        NavItem
    },
    setup() {
        // Dynamiczna zmiana znacznika nawigacji
        const globalState = inject('globalState');
        const sectionToIndexMap = {
            'o-mnie': 0,
            'doswiadczenie': 1,
            'projekty': 2,
            'kontakt': 3,
        };
        const activeIndex = computed(() => {
            return sectionToIndexMap[globalState.activeSection] ?? null;
        });

        // Zmiana budowy nawigacji
        const route = useRoute();
        const navLinks = computed(() => {
            if (route.name === 'home') {
                return [
                    { text: 'O mnie', url: '#o-mnie' },
                    { text: 'Doświadczenie', url: '#doswiadczenie' },
                    { text: 'Projekty', url: '#projekty' },
                    { text: 'Kontakt', url: '#kontakt' }
                ]
            } else if (route.name === 'archive') {
                return [
                    { text: 'Powrót', url: '/'}
                ]
            } else return null;
        });

        return { activeIndex, navLinks };
    },
    data() {
        return {
            socialLinks: [
                { icon: 'bi-messenger', url: 'https://m.me/weclawiak.eryk' },
                { icon: 'bi-whatsapp', url: 'https://api.whatsapp.com/send?phone=48660469587' },
                { icon: 'bi-linkedin', url: 'https://www.linkedin.com/in/eryk-weclawiak' },
                { icon: 'bi-github', url: 'https://github.com/eryk-weclawiak' }
            ]
        }
    }
}
</script>