import { createApp, reactive } from 'vue';
import App from './App.vue';
import router from './router/router.js';
import './assets/main.css';

import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { BiMessenger, BiLinkedin, BiGithub, BiWhatsapp, BiArrowLeftShort, FaArrowLeft, BiBoxArrowUpRight  } from 'oh-vue-icons/icons';

const globalState = reactive({
    activeSection: null
});

addIcons(BiMessenger, BiLinkedin, BiGithub, BiWhatsapp, BiArrowLeftShort, FaArrowLeft, BiBoxArrowUpRight );

const app = createApp(App);
app.provide('globalState', globalState);
app.component("v-icon", OhVueIcon);
app.use(router);
app.mount("#app");
