<template>
    <div :class="['section-header tracking-widest text-xl font-semibold text-cWhite', positionClass]">
        <h3>{{ text }}</h3>
    </div>
</template>

<script>
export default {
    name: "SectionHeader",
    props: {
        text: {
            type: String,
            required: true
        },
        position: {
            type: String,
            required: false,
            default: "left"
        }
    },
    computed: {
        positionClass() {
            return `position-${this.position}`;
        }
    }
}
</script>

<style scoped>
    .section-header h3 {
        position: relative;
        width: fit-content;
    }

    .section-header h3::after {
        @apply bg-cBurgundy;
        content: '';
        position: absolute;
        width: 80%;
        height: 5px;
        bottom: -12px;
        max-width: 200px;
        box-shadow: 0 0 10px #800020, 0 0 20px #800020, 0 0 30px #800020;
    }

    .section-header.position-none h3::after {
        display: none;
    }

    .section-header.position-left h3::after {
        left: 0;
    }

    .section-header.position-middle h3::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .section-header.position-right h3::after {
        right: 0;
    }
</style>