<template>
    <li class="nav-link text-cMidGrey" :class="{ 'active': isActive}">
        <a :href="url" class="flex items-center space-x-3">
            <div class="nav-indicator"></div>
            <div>
                <span>{{ text }}</span>
            </div>
        </a>
    </li>
</template>

<style scoped>
.nav-link a {
    @apply transition-colors duration-300; 
}

.nav-link:hover a, .nav-link.active a {
    @apply text-cWhite;
}

.nav-indicator {
    @apply border-cMidGrey border transition-all duration-300;
    width: 50px;
}

.nav-link:hover .nav-indicator, .nav-link.active .nav-indicator {
    width: 90px; 
}
</style>

<script>
export default {
    name: 'NavItem',
    props: {
        url: {
            type: String, 
            required: true
        },
        text: {
            type: String,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        }
    }
}
</script>