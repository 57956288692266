<template>
    <div class="space-y-24">

        <!-- About me section -->
        <section id="o-mnie" class="space-y-10" ref="aboutSection">
            <section-header text="O mnie" />
            <div class="text-cLightGray space-y-5 tracking-widest leading-8 text-justify">
                <p>
                    Cześć! Jestem programistą z pasją do tworzenia nowoczesnych rozwiązań cyfrowych. Początek mojej przygody
                    z programowaniem był skromny - od prostych skryptów do ambitniejszych projektów. Każdy krok wprowadzał
                    mnie głębiej w fascynujący świat IT. Studiując informatykę na Uniwersytecie Merito, odkryłem, że moje
                    prawdziwe zainteresowanie to nie tylko e-commerce, ale także szeroka gama technologii.
                </p>
                <p>
                    Poza godzinami kodowania, relaksuję się tworząc muzykę oraz grając w gry. Jestem pasjonatem uczenia się
                    i eksplorowania, co oznacza, że zawsze jestem gotowy podjąć nowe wyzwania i rozszerzać swoje horyzonty
                    technologiczne w różnych dziedzinach.
                </p>
            </div>
            <div class="text-cWhite flex flex-col items-center w-full space-y-5">
                <section-header text="Tech Stack" position="none" />
                <div class="flex flex-wrap gap-8 lg:gap-10 justify-center items-center w-full lg:w-9/12">
                    <tech-icon v-for="tech in techStack" :key="tech.id" :text="tech.text" :src="tech.src" />
                </div>
            </div>
        </section>

        <!-- Experience section -->
        <section id="doswiadczenie" class="space-y-4" ref="carrierSection">
            <section-header text="Doświadczenie" />
            <carrier-card v-for="c in carriers" :key="c.id" :period="c.period" :role="c.role" :company="c.company" :tech="c.tech"
                :description="c.description" />
            <redirect-link text="Zobacz pełne CV" url="/doc/curriculum-vitae.pdf" target="_blank" />

        </section>

        <!-- Projects section -->
        <section id="projekty" class="space-y-10" ref="projectsSection">
            <section-header text="Projekty" />
            <project-card v-for="p in projects" :key="p.id" :title="p.title" :thumbnail="p.thumbnail"
                :description="p.description" :tech="p.tech" />
            <redirect-link text="Zobacz więcej projektów" url="/archive" />
        </section>

        <!-- Contact section -->
        <section id="kontakt" class="space-y-10" ref="contactSection">
            <section-header text="Chcesz porozmawiać o współpracy? Świetnie!" />
            <div class="text-cLightGray space-y-14 tracking-widest leading-8 flex flex-col items-center">
                <p class="text-center">
                    Hej, chcesz porozmawiać o swoim pomyśle? Jako samodzielny programista, chętnie pochylę się nad Twoimi
                    potrzebami i projektami. Jeśli masz jakiekolwiek pytania lub chcesz porozmawiać o możliwej współpracy,
                    napisz do mnie! Chętnie odpowiem na wszystko.
                </p>
                <div>
                    <a href="mailto:eryk.weclawiak1@gmail.com" class="neon-border">
                        <span class="font-niramit font-bold">Wpadnij na kawę!</span>
                    </a>
                </div>
            </div>
        </section>

    </div>
</template>

<style>
.neon-border {
    border: 3px solid #800020;
    border-radius: 8px;
    padding: 12px 24px;
    box-shadow: 0 0 10px #800020, 0 0 20px #800020, 0 0 30px #800020;
    transition: all 0.3s ease-in-out;
}

.neon-border:hover {
    box-shadow: 0 0 20px #800020, 0 0 30px #800020, 0 0 40px #800020;
    transform: scale(1.05);
}

.neon-border:hover span {
    color: #DB7093;
    text-shadow: 0 0 8px #C71585;
    transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
}
</style>

<script>
import SectionHeader from "../common/components/SectionHeader.vue";
import TechIcon from "../common/components/TechIcon.vue";
import ProjectCard from "../common/components/ProjectCard.vue";
import RedirectLink from "../common/components/RedirectLink.vue";
import CarrierCard from "../common/components/CarrierCard.vue";
import { inject, onMounted, onUnmounted } from "vue";

export default {
    name: 'HomePage',
    components: {
        SectionHeader,
        TechIcon,
        ProjectCard,
        RedirectLink,
        CarrierCard
    },
    setup() {
        const state = inject('globalState');

        const updateActiveSectionOnScroll = () => {
            const sections = [
                document.getElementById('o-mnie'),
                document.getElementById('doswiadczenie'),
                document.getElementById('projekty'),
                document.getElementById('kontakt'),
            ];

            if (window.scrollY === 0) {
                state.activeSection = 'o-mnie';
                return;
            }

            const scrollBottom = window.scrollY + window.innerHeight;
            if (scrollBottom >= document.body.offsetHeight) {
                state.activeSection = 'kontakt';
                return;
            }

            let closestSection = '';
            let minOffset = Infinity;

            sections.forEach(section => {
                const offset = Math.abs(section.offsetTop - window.scrollY);
                if (offset < minOffset) {
                    minOffset = offset;
                    closestSection = section.id;
                }
            });

            state.activeSection = closestSection;
        }

        onMounted(() => {
            updateActiveSectionOnScroll();
            window.addEventListener('scroll', updateActiveSectionOnScroll);
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', updateActiveSectionOnScroll);
        });
    },
    data() {
        return {
            techStack: [
                { text: 'C#', src: '/img/tech-icons/CSharp.webp' },
                { text: 'Python', src: '/img/tech-icons/Python.webp' },
                { text: 'PHP', src: '/img/tech-icons/PHP.webp' },
                { text: 'Laravel', src: '/img/tech-icons/Laravel.webp' },                
                { text: 'HTML', src: '/img/tech-icons/HTML5.webp' },
                { text: 'CSS', src: '/img/tech-icons/CSS3.webp' },
                { text: 'TailwindCSS', src: '/img/tech-icons/Tailwind-CSS.webp' },
                { text: 'JavaScript', src: '/img/tech-icons/JavaScript.webp' },
                { text: 'Vue.js', src: '/img/tech-icons/Vue.js.webp' },
                { text: 'Figma', src: '/img/tech-icons/Figma.webp' },
                { text: 'MySQL', src: '/img/tech-icons/MySQL.webp' },
                { text: 'PostgreSQL', src: '/img/tech-icons/PostgresSQL.webp' },
                { text: 'SQLite', src: '/img/tech-icons/SQLite.webp' },
                { text: 'Git', src: '/img/tech-icons/Git.webp' },
            ],

            projects: [
                {
                    title: 'Integracja z Baselinker',
                    thumbnail: '/img/project-thumbnails/baselinker.webp',
                    description: 'Projekt obejmował opracowanie połączenia systemu ERP z platformą Baselinker. Realizowane zadania obejmoway przesyłanie danych o produktach, dynamiczne tworzenie nazw aukcji, aktualizację cen oraz stanów magazynowych oraz wszelkich innych aspektów, które przyczyniły się do znaczącego ułatwienia i usprawnienia procesu wystawiania aukcji na platformach sprzedażowych. Dodatkowo przeprowadzałem integrację konta z platformami, takimi jak Amazon, Allegro i eBay, co ułatwiło zarządzanie sprzedażą online.',
                    tech: ['C#', 'REST API', 'JSON', 'PostgreSQL', 'Microsoft SQL Server']
                },
                {
                    title: 'Integracja z Mastersport.pl',
                    thumbnail: '/img/project-thumbnails/mastersport.webp',
                    description: 'Projekt skoncentrowany był na integracji systemu ERP z e-sklepem mastersport.pl, działającym na platformie NetCorner firmy Komputronik.  Moim głównym zadaniem było zapewnienie płynnego przepływu danych, w tym szczegółowych informacji o produktach. Moje innowacyjne rozwiązania przyczyniły się do zwiększenia efektywności zarządzania ofertą online sklepu, poprawiając jednocześnie doświadczenie użytkowników końcowych. Efektem mojej pracy było nie tylko utrzymanie aktualności i spójności danych w całym ekosystemie sprzedażowym, ale także znaczące usprawnienie procesów biznesowych, co przekładało się na wzrost satysfakcji klientów i lepsze wyniki sprzedażowe.',
                    tech: ['C#', 'WCF', 'SOAP API', 'Microsoft SQL Server']
                },
                {
                    title: 'Projekt i wykonanie strony slomapiotr.pl',
                    thumbnail: '/img/project-thumbnails/piotr-sloma-ekspert-finansowy.webp',
                    description: 'Realizacja strony \'slomapiotr.pl\' obejmowała kompleksowy proces tworzenia, rozpoczynając od opracowania makietą w Figma, po ostateczną implementację strony internetowej. Projektowanie to umożliwiło szczegółowe zaplanowanie estetyki oraz funkcjonalności, co przyczyniło się do płynnej realizacji. Strona ta, dedykowana ekspertowi finansowemu Piotrowi Słomie, łączy profesjonalny wygląd z praktycznymi elementami nawigacyjnymi, spełniając potrzeby zarówno klienta, jak i użytkowników końcowych.',
                    tech: ['PHP', 'SCSS', 'JavaScript', 'Figma']
                }
            ],

            carriers: [
                {
                    period: 'od 2021',
                    role: 'Programista',
                    company: 'Mastersport',
                    description: 'Od 2021 roku pracuję jako programista w Mastersport.pl, gdzie głównie skupiam się na tworzeniu oprogramowania do automatyzacji procesów biznesowych oraz integracji systemów ERP z różnymi kanałami sprzedażowymi. Oprócz tego, współpracuję z zewnętrznymi firmami IT w zakresie wdrażania nowych rozwiązań technologicznych. Zajmuję się także podstawowym wsparciem technicznym dla sklepów stacjonarnych. Moja rola łączy w sobie programowanie z praktycznym zastosowaniem technologii w realnym środowisku biznesowym.',
                    tech: ['C#', 'Python', 'PHP', 'Laravel','Microsoft SQL Server', 'PostgreSQL', 'REST API', 'SOAP API', 'TailwindCSS', 'CSS' ]
                },
                {
                    period: '2019 - 2021',
                    role: 'Serwisant',
                    company: 'MDTronic',
                    description: 'Moja praca w MDTRONIC jako serwisant była moim pierwszym krokiem w branży IT. Koordynowałem dział testowania, zapewniając płynność i skuteczność procesów. Moja odpowiedzialność obejmowała kontrolę jakości naprawianych produktów, zarówno pod kątem technicznym, jak i estetycznym. Specjalizowałem się również w naprawie poleasingowego sprzętu IT, przygotowując go do dalszej sprzedaży. To doświadczenie pozwoliło mi na zdobycie cennych umiejętności technicznych i zrozumienie znaczenia jakości w branży IT.',
                    tech: ['Linux', 'Bash', 'Navicat']
                }
            ]
        }
    }
};
</script>

